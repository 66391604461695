//IMPORTING IMAGES
function importAllApp(r) {
    let images = {};
    r.keys().forEach((item, index) => { images[item.replace('./', '')] = r(item); });
    return images
}
const appImages = importAllApp(require.context('./App/imgs', true, /\.(png|jpe?g|svg|gif|mp4)$/));

// Get the token from localStorage
const token = localStorage.getItem('loginToken');

const config = {
    menuApi: process.env.REACT_APP_MENU_API,
    companyName: 'Safeer El Arz',
    companyLogo: appImages['logo.png'],
    favIcon: '',
    siteDirection: '',
    meta: {
        main: {
            title: 'Safeer ElArz | سفير الأرز',
            description: "Discover the menu of Safir Al Arz Restaurant. Safir Al Arz is the ideal place for lovers of Lebanese food. We offer a diverse menu of Wonderful additions to a wide variety of food and cold and hot drinks. Celebrities and figures from the Arab world at Safir Al Arz Restaurant😁😍. | إكتشف منيو مطعم سفير الأرز.. سفير الآرز المكان المثالي لمحبي الأكل البناني  نقدّم قائمة متنوعة من  إضافات رائعة من أصناف وتشكيلة واسعة من الطعام والمشروبات الباردة والساخنة  مشاهير وأعلاميين الوطن العربي في مطعم سفير الآرز",
            keywords: "Safir Al Arz Restaurant, Lebanese food, Diverse menu, Wide variety of food, Cold drinks, Hot drinks, Celebrities, Arab world figures, Cairo, Mohandeseen, Egyptian restaurant, Lebanese cuisine, Ideal place for Lebanese food lovers, Wonderful additions, Arab world celebrities, Egypt, Safir Al Arz, Restaurant in Mohandeseen, Lebanese dining in Cairo, Arabic food, مطعم سفير الأرز, الأكل البناني, قائمة متنوعة, تشكيلة واسعة من الطعام, المشروبات الباردة, المشروبات الساخنة, مشاهير, أعلاميين الوطن العربي, القاهرة, المهندسين, مطعم مصري, المطبخ اللبناني, المكان المثالي لمحبي الأكل البناني, إضافات رائعة, مشاهير الوطن العربي, مصر, سفير الأرز, مطعم في المهندسين, الطعام اللبناني في القاهرة, الأكل العربي",
            image: appImages['logo.png'],
            url: 'https://safeer-elarz.4design-adv.com',
        },
        home: {
            title: '',
            description: '',
            keywords: '',
            image: '',
            url: 'https://safeer-elarz.4design-adv.com/',
        },
        contact: {
            title: 'Contact Us | تواصل معنا',
            description: '',
            keywords: '',
            image: '',
            url: 'https://safeer-elarz.4design-adv.com/contact',
        },
        about: {
            title: 'About Us | عنا',
            description: '',
            keywords: '',
            image: '',
            url: 'https://safeer-elarz.4design-adv.com/about',
        },
        menus: {
            title: 'Our Menus | منيوهاتنا',
            description: '',
            keywords: '',
            image: '',
            url: 'https://safeer-elarz.4design-adv.com/menus',
        },
    },
    homeSlides: [
        {
            title: 'سفير الأرز',
            description: 'إكتشف منيو مطعم سفير الأرز',
            backgroundImage: appImages['slider/image-1.jpeg'],
            link: '/link-1'
        },
    ],
    contact: {
        hotline: [],
        phones: [
            {
                phone: '0237604017',
                link: 'tel:0237604017'
            },
            {
                phone: '01204222020',
                link: 'tel:+201204222020'
            }
        ],
        emails: [
            {
                name: 'info',
                email: 'safirelarz@hotmail.com'
            }
        ],
        addresses: [
            ' شارع عبد الحليم حسين متفرع من شارع عبد المنعم رياض من البطل احمد عبد العزيز المهندسين, Giza, Egypt',
        ],
        embedMapLink: 'https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d6519.20930088829!2d31.204196217589605!3d30.053192339878212!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x1458412feca0581f%3A0x65f99fc51a0bb925!2sSalah%20El-Deen%20Mostafa%2C%20Ad%20Doqi%2C%20Dokki%2C%20Giza%20Governorate%203751323!5e0!3m2!1sen!2seg!4v1717255551900!5m2!1sen!2seg',
        socialMedia: {
            facebook: 'https://www.facebook.com/SafirElArzRestaurant',
            instagram: 'https://www.instagram.com/safirelarzeg?igsh=cHh4bWlmNjVrdHB6',
            x: '',
            linkedin: '',
            pinterest: '',
            behance: '',
            dribble: '',
            snapchat: '',
            tiktok: '',
        },
    },

    headerBackgroundImage: '',
    headerBackgroundColor: '',

    footerBackgroundColor: '',
    footerBackgroundImage: ''
}
// menuApi: 'https://fourdesign-adv-luxer-menu.onrender.com'

export default config;